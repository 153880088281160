import './loader.scss';
export default ({active}) => (<div className={`banter-loader ${active ? 'active' : ''}`}>
<div className="banter-loader__box"></div>
<div className="banter-loader__box"></div>
<div className="banter-loader__box"></div>
<div className="banter-loader__box"></div>
<div className="banter-loader__box"></div>
<div className="banter-loader__box"></div>
<div className="banter-loader__box"></div>
<div className="banter-loader__box"></div>
<div className="banter-loader__box"></div>
</div>)